import React, { useEffect, useState } from "react";
import { BsChevronDoubleRight } from "react-icons/bs";
import SenderIcon from "../../../assets/create-shipment/sender.svg";
import SenderActiveIcon from "../../../assets/create-shipment/sender-active.svg";
import ReceiverIcon from "../../../assets/create-shipment/receiver.svg";
import ReceiverActiveIcon from "../../../assets/create-shipment/receiver-active.svg";
import PaymentIcon from "../../../assets/create-shipment/payment.svg";
import PaymentActiveIcon from "../../../assets/create-shipment/payment-active.svg";
import ConfirmIcon from "../../../assets/create-shipment/confirm.svg";
import ConfirmActiveIcon from "../../../assets/create-shipment/confirm-active.svg";
import { getShipmentSettings2Url } from "../../../library/URLs";
import Sender from "./Sender";
import Receiver from "./Receiver";
import Payment from "./Payment";
import Confirm from "./Confirm";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";

const LocalShipment = ({ shipmentInfo }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [carriers, setCarriers] = useState([]);
  const [pricingMethod, setPricingMethod] = useState("Yes");
  const [formData, setFormData] = useState({
    sender_details: {
      fullname: "",
      email: "",
      phone: "",
      address: "",
      coordinates: "",
    },
    receiver_details: {
      fullname: "",
      email: "",
      phone: "",
      address: "",
      coordinates: "",
    },
    payment_method: "",
    insurance: "No",
    vehicle_type: "Motorbike",
    transporter: "",
    description: "",
    pickup_cost: "",
    selectedCarrier: {},
    term: false,
  });

  useEffect(() => {

    if(shipmentInfo) {
      let recreate_shipment = {
        ...formData,
        sender_details: {
          ...formData.sender_details,
          fullname: shipmentInfo?.sender_name,
          email: shipmentInfo?.sender_email,
          phone: shipmentInfo?.sender_phone_no,
          address: shipmentInfo?.pick_up_location,
        },
        receiver_details: {
          ...formData?.receiver_details,
          fullname: shipmentInfo?.recipient_name,
          email: shipmentInfo?.recipient_email,
          phone: shipmentInfo?.recipient_phone_no,
          address: shipmentInfo?.drop_off_location
        }
      };

      setFormData(recreate_shipment);
    };
    // eslint-disable-next-line
  }, [shipmentInfo]);

  const getShipmentSettings = async () => {
    await axios
      .post(getShipmentSettings2Url, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const pricing_method = response["data"]["data"];

          setPricingMethod(pricing_method["is_local_price_generated"]);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(() => {
    getShipmentSettings();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const nextPage = () => {
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleTabSelect = (id) => {
    if(currentPage > id) {
      setCurrentPage(id)
    }
  }

  return (
    <div>
      <ul className="create-shipment-form-tabs">
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 0 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 0 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(0)}
        >
          {currentPage === 0 ? (
            <img src={SenderActiveIcon} alt="icon" />
          ) : (
            <img src={SenderIcon} alt="icon" />
          )}
          Sender
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 0 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 1 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 1 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(1)}
        >
          {currentPage === 1 ? (
            <img src={ReceiverActiveIcon} alt="icon" />
          ) : (
            <img src={ReceiverIcon} alt="icon" />
          )}
          Receiver
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 1 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 2 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 2 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(2)}
        >
          {currentPage === 2 ? (
            <img src={PaymentActiveIcon} alt="icon" />
          ) : (
            <img src={PaymentIcon} alt="icon" />
          )}
          Pricing & Payment
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 2 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 3 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 3 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(3)}
        >
          {currentPage === 3 ? (
            <img src={ConfirmActiveIcon} alt="icon" />
          ) : (
            <img src={ConfirmIcon} alt="icon" />
          )}
          Confirm
        </li>
      </ul>
      <div>
        {currentPage === 0 ? (
          <Sender
            nextPage={nextPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : currentPage === 1 ? (
          <Receiver
            nextPage={nextPage}
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
            setCarriers={setCarriers}
            pricingMethod={pricingMethod}
          />
        ) : currentPage === 2 ? (
          <Payment
            nextPage={nextPage}
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
            carriers={carriers}
            pricingMethod={pricingMethod}
          />
        ) : currentPage === 3 ? (
          <Confirm
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default LocalShipment;
