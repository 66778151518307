import React, { useEffect, useState } from "react";
import {
  descriptionExerpt,
  fetchAllPackageItems,
  fetchPackageTotalWeight,
  getInitials,
  getRandomColor,
  valueSummation,
  weightSummation,
} from "../Utilities";
import PackagesIcon from "../../../assets/create-shipment/package-page.svg";
import BoxIcon from "../../../assets/create-shipment/box.svg";
import BoxAddIcon from "../../../assets/create-shipment/box_add.svg";
import { BsPencil, BsPlus, BsTrash } from "react-icons/bs";
import AddItemIcon from "../../../assets/create-shipment/add-item.svg";
import AddPackageItemDialog from "../../../modals/AddPackageItemDialog";
import EditPackageItemDialog from "../../../modals/EditPackageItemDialog";
import { Col, Row } from "react-bootstrap";
import { SelectBox, TextBox } from "../../../components/InputFields";
import {
  getPackagingURL,
  calculateDHLURL,
  calculateNewInterShipmentsUrl
} from "../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
import countries from "../../../files/countries.json";
import { toast } from "react-toastify";
import { generateTransactionID } from "../../../library/utilities";

const Package = ({
  nextPage,
  previousPage,
  formData,
  setFormData,
  setCarriers,
}) => {
  let packages = formData?.packages;

  const [isLoading, setIsLoading] = useState(false);

  const [containerTypes, setContainerTypes] = useState([]);
  const getPackaging = async () => {
    await axios
      .post(getPackagingURL, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const packaging = response["data"]["data"].map((pack) => {
            return {
              label: pack?.name,
              value: pack?.name,
              length: pack?.length,
              width: pack?.width,
              height: pack?.height,
            };
          });

          setContainerTypes(packaging);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(() => {
    getPackaging();
  }, []);

  const calculateRates = async () => {
    let packageWeight = fetchPackageTotalWeight(formData?.packages);

    const data = {
      ...UserProfileData(),
      length: formData?.packages[0]?.package_length,
      height: formData?.packages[0]?.package_height,
      breadth: formData?.packages[0]?.package_width,
      weight: packageWeight,
      sender_country_code: countries?.find(ctry => ctry?.name === formData?.sender_details?.country)?.code,
      receiver_country_code: countries?.find(ctry => ctry?.name === formData?.receiver_details?.country)?.code,
      sender_address: formData?.sender_details?.address_1,
      sender_address_2: formData?.sender_details?.address_2,
      sender_city: formData?.sender_details?.city,
      sender_state: formData?.sender_details?.state,
      sender_postal_code: formData?.sender_details?.postal_code,
      sender_phone: formData?.sender_details?.phone,
      receiver_address: formData?.receiver_details?.address_1,
      receiver_address_2: formData?.receiver_details?.address_2,
      receiver_city: formData?.receiver_details?.city,
      receiver_county: formData?.receiver_details?.state,
      receiver_postal_code: formData?.receiver_details?.postal_code,
      receiver_phone: formData?.receiver_details?.phone,
      packages: fetchAllPackageItems(formData?.packages)
    };
    setIsLoading(true);

    await axios
      .post(calculateNewInterShipmentsUrl, data)
      .then(function (response) {
        setIsLoading(false);

        if (response["data"]["success"] === true) {
          setCarriers(response["data"]["data"]["carriers"]);
          nextPage();
        } else {
          toast.error(response["data"]["message"]);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const [selectedPackage, setSelectedPackage] = useState(0);
  const [addPackageModal, setAddPackageModal] = useState(false);
  const [editPackageModal, setEditPackageModal] = useState(null);
  const [showProhibitedItems, setShowProhibitedItems] = useState(false);
  const [showAdditionalServices, setShowAdditionalServices] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState("");

  // useEffect(() => {
  //   setShowProhibitedItems(true);
  // }, []);

  // Handle Package Item modals
  const openAddItem = () => {
    setAddPackageModal(true);
  };

  const closeAddItem = () => {
    setAddPackageModal(false);
  };

  const openEditItem = (id) => {
    setEditPackageModal(id);
  };

  const closeEditItem = () => {
    setEditPackageModal(null);
  };

  const handleCalculateShipmentSuccess = (data) => {
    setFormData({
      ...formData,
      shipInfo: data?.data,
    });
    // if (data?.data?.success) {

    //   updateState("shipmentInfo", {
    //     ...contextData?.shipmentInfo,
    //     shipInfo: data?.data,
    //   });
    //   nextPage();
    // } else {
    //   toast.error(data?.data?.message);
    // }
  };

  // Add New Package
  const handleAddPackage = () => {
    let packagesCopy = [...formData.packages];
    packagesCopy.push({
      number_of_items: 0,
      package_value: 0,
      package_weight: 0,
      package_length: "1",
      package_width: "1",
      package_height: "1",
      description: "Package",
      package_items: [],
    });

    setFormData({
      ...formData,
      packages: packagesCopy,
    });
    setSelectedPackage(selectedPackage + 1);
  };

  // Remove a Package
  const handleRemovePackage = () => {
    if (formData.packages?.length < 2) {
      return;
    }

    let packagesCopy = [...formData.packages];
    packagesCopy.splice(selectedPackage, 1);

    setFormData({
      ...formData,
      packages: packagesCopy,
    });
    setSelectedPackage(selectedPackage - 1);
    window.scrollTo(0, 0);
  };

  // Add new item to package
  const handleAddPackageItem = (packageObj) => {
    let packagesCopy = [...formData.packages];

    let packageItemCopy = { ...packagesCopy[selectedPackage] };

    packageItemCopy.package_items.push(packageObj);
    packageItemCopy.number_of_items += 1;
    packageItemCopy.package_weight = weightSummation(
      packageItemCopy.package_items
    );
    packageItemCopy.package_value = valueSummation(
      packageItemCopy.package_items
    );

    packagesCopy[selectedPackage] = packageItemCopy;

    setFormData({
      ...formData,
      packages: packagesCopy,
    });
    closeAddItem();
  };

  // Remove item from package
  const handleRemovePackageItem = (id) => {
    let packagesCopy = [...formData.packages];

    let packageItemCopy = { ...packagesCopy[selectedPackage] };
    let prevWeight = packageItemCopy?.package_items[id]?.weight;
    let prevValue = packageItemCopy?.package_items[id]?.value;

    packageItemCopy.number_of_items -= 1;
    packageItemCopy.package_weight = Math.round((packageItemCopy.package_weight - parseFloat(prevWeight)) * 100) / 100;
    packageItemCopy.package_value = Math.round((packageItemCopy.package_value - parseFloat(prevValue)) * 100) / 100;


    packagesCopy[selectedPackage] = packageItemCopy;

    packagesCopy[selectedPackage].package_items.splice(id, 1);

    setFormData({
      ...formData,
      packages: packagesCopy,
    });
  };

  // Edit package item
  const handleEditPackageItem = (packageObj) => {
    let packagesCopy = [...formData.packages];

    let packageItemCopy = { ...packagesCopy[selectedPackage] };

    packageItemCopy.package_items[editPackageModal] = packageObj;
    packageItemCopy.package_weight = weightSummation(
      packageItemCopy.package_items
    );
    packageItemCopy.package_value = valueSummation(
      packageItemCopy.package_items
    );

    packagesCopy[selectedPackage] = packageItemCopy;

    setFormData({
      ...formData,
      packages: packagesCopy,
    });
    closeEditItem();
  };

  function getTotalValue(items) {
    let totalValue = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const quantity = parseFloat(item.quantity);
      const value = parseFloat(item.value);
      totalValue += value * quantity;
    }
    return totalValue;
  }

  let currency =
    formData?.sender_details?.country !== "Nigeria" ||
    formData?.receiver_details?.country !== "Nigeria"
      ? "$"
      : "N";

  const handlePackageDimensionsInput = (e) => {
    if (selectedContainer !== "") {
      setSelectedContainer("");
    }

    const { name, value } = e.target;
    let packagesCopy = [...formData.packages];

    packagesCopy[selectedPackage][name] = value;

    setFormData({
      ...formData,
      packages: packagesCopy,
    });
  };

  const handleSavedContainer = (e) => {
    const { value } = e.target;

    let packagesCopy = [...formData.packages];
    let selected_container = containerTypes?.find(
      (container) => container.value === value
    );

    packagesCopy[selectedPackage]["package_length"] = selected_container.length;
    packagesCopy[selectedPackage]["package_height"] = selected_container.height;
    packagesCopy[selectedPackage]["package_width"] = selected_container.width;

    setFormData({
      ...formData,
      packages: packagesCopy,
    });
    setSelectedContainer(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    calculateRates();
  };

  return (
    <div className="create-shipment-grid">
      <AddPackageItemDialog
        isModalVisible={addPackageModal}
        handleCloseDialog={closeAddItem}
        shipment_type="international"
        onSubmit={handleAddPackageItem}
        insurance={formData?.insurance}
      />
      <EditPackageItemDialog
        isModalVisible={editPackageModal !== null}
        handleCloseDialog={closeEditItem}
        shipment_type="international"
        onSubmit={handleEditPackageItem}
        packageItem={
          formData?.packages[selectedPackage]?.package_items[editPackageModal]
        }
        insurance={formData?.insurance}
      />
      <div className="create-shipment-info">
        <img src={PackagesIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Package Details</p>
            <p className="create-shipment-info-sub">
              What package(s) do you want to send in this shipment?
            </p>
          </div>
        </div>
      </div>
      <div className="create-shipment-info-form">
        <div className="packages-wrapper">
          <div className="packages-wrapper-top">
            <div className="packages-wrapper-top-info">
              <div className="packages-wrapper-top-info-title">
                <img src={BoxIcon} alt="box" />
                Packages
              </div>
              <button
                className="packages-wrapper-top-info-btn-add"
                onClick={handleAddPackage}
              >
                <img src={BoxAddIcon} alt="box" />
                Add Package
              </button>
            </div>
            <ul className="packages-wrapper-packages">
              {formData.packages.map((_, id) => (
                <li
                  key={id}
                  className={` packages-wrapper-packages-package ${
                    selectedPackage === id &&
                    "packages-wrapper-packages-package-active"
                  }`}
                  onClick={() => setSelectedPackage(id)}
                >
                  <span className="d-none d-md-block">Package</span>{" "}
                  <span className="d-md-none">Pck</span> {id + 1}
                </li>
              ))}
            </ul>
          </div>
          <ul className="packages-wrapper-items">
            {packages[selectedPackage]?.package_items?.map(
              (package_item, id) => (
                <li
                  key={id}
                  className={`packages-wrapper-items-item ${
                    id + 1 ===
                      packages[selectedPackage]?.package_items?.length &&
                    "border-0"
                  }`}
                >
                  <div className="packages-wrapper-items-item-grid">
                    <div className="packages-wrapper-items-title">
                      <div
                        style={{ backgroundColor: getRandomColor(), color: "#FFF" }}
                        className={`packages-wrapper-items-title-initials`}
                      >
                        {getInitials(package_item.category)}
                      </div>
                      <div className="px-5">
                        <p className="packages-wrapper-items-title-category">
                          {package_item.category}{" "}
                        </p>
                        <p className="packages-wrapper-items-title-description">
                          {descriptionExerpt(package_item.description)}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="packages-wrapper-items-quantity-label">
                        QUANTITY
                      </p>
                      <p className="packages-wrapper-items-quantity">
                        {package_item.quantity}
                      </p>
                    </div>
                    <div className="packages-wrapper-items-btn-wrap">
                      <button
                        className="packages-wrapper-items-btn-edit"
                        onClick={() => openEditItem(id)}
                      >
                        <BsPencil />
                      </button>
                      <button
                        className="packages-wrapper-items-btn-delete"
                        onClick={() => handleRemovePackageItem(id)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  </div>
                </li>
              )
            )}
            <li
              className="packages-wrapper-items-add-item-wrap"
              onClick={openAddItem}
            >
              <div>
                <div className="packages-wrapper-items-add-item-icon">
                  <img src={AddItemIcon} alt="icon" />
                  <p className="packages-wrapper-items-add-item-icon-text">
                    Add Item
                  </p>
                </div>
                <p className="packages-wrapper-items-add-item-msg">
                  Add a new item to your package
                </p>
              </div>
            </li>
          </ul>
          <div className="packages-wrapper-dimensions">
            <p className="packages-wrapper-dimensions-title">
              Package Dimensions{" "}
              <span className="packages-wrapper-dimensions-title-sub">
                [ie container]
              </span>
            </p>
            <div className="packages-wrapper-dimensions-form">
              <Row>
                <Col sm={12} lg={6}>
                  <p className="packages-wrapper-dimensions-label">
                    Saved Dimensions{" "}
                    <span className="packages-wrapper-dimensions-label-sub">
                      [ie container]
                    </span>
                  </p>
                  <SelectBox
                    name="saved_containers"
                    value={selectedContainer}
                    onChange={handleSavedContainer}
                    placeholder="Select from saved containers"
                    options={containerTypes}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <p className="packages-wrapper-dimensions-label">
                New Dimensions{" "}
                <span className="packages-wrapper-dimensions-label-sub">
                  [ie container]
                </span>
              </p>
              <Row>
                <Col sm={12} lg={4}>
                  <label htmlFor="">Height</label>
                  <TextBox
                    name="package_height"
                    value={formData.packages[selectedPackage].package_height}
                    onChange={handlePackageDimensionsInput}
                    prefix="CM"
                    placeholder="Package height"
                    type="tel"
                  />
                </Col>
                <Col sm={12} lg={4}>
                  <label htmlFor="">Length</label>
                  <TextBox
                    name="package_length"
                    value={formData.packages[selectedPackage].package_length}
                    onChange={handlePackageDimensionsInput}
                    prefix="CM"
                    placeholder="Package length"
                    type="tel"
                  />
                </Col>
                <Col sm={12} lg={4}>
                  <label htmlFor="">Breadth</label>
                  <TextBox
                    name="package_width"
                    value={formData.packages[selectedPackage].package_width}
                    onChange={handlePackageDimensionsInput}
                    prefix="CM"
                    placeholder="Package width"
                    type="tel"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="packages-wrapper-summary">
            <p className="packages-wrapper-summary-title">Package Summary</p>
            <ul>
              <li className="border-b">
                <p className="packages-wrapper-summary-label">Total Quantity</p>
                <p className="packages-wrapper-summary-value">
                  {packages[selectedPackage]?.number_of_items}
                </p>
              </li>
              <li className="border-b">
                <p className="packages-wrapper-summary-label">Total Value</p>
                <p className="packages-wrapper-summary-value">
                  USD{" "}
                  {parseFloat(
                    packages[selectedPackage]?.package_value
                  ).toLocaleString("en-US")}
                </p>
              </li>
              <li>
                <p className="packages-wrapper-summary-label">Total Weight</p>
                <p className="packages-wrapper-summary-value">
                  {packages[selectedPackage]?.package_weight} KG
                </p>
              </li>
            </ul>
            <button
              className="packages-wrapper-summary-delete-btn"
              onClick={handleRemovePackage}
            >
              <BsTrash />
              Delete Package
            </button>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={handleSubmit}
              // disabled={validatePage()}
            >
              {isLoading ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;
