import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  getAPICarriersUrl,
  addCarrierTypeUrl,
  uploadRateDocumentUrl,
  getApiCarrierAccountsUrl,
  getCarrierShippingPlansUrl,
} from "../library/URLs";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import {
  MultiSelectBox,
  SelectBox,
  TextBox,
  TextFieldBox,
  UploadImgField,
} from "../components/InputFields";
import { BsX } from "react-icons/bs";
import countries from "../files/countries.json";
import currencies from "../files/currencies.json";
import { SessionManagement } from "../library/SessionManagement";
import Tooltip from "@mui/material/Tooltip";
import { IoMdHelpCircle } from "react-icons/io";
import RatesDocumentDialog from "./RateDocumentsDialog";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

// const ContentFieldsActionButton = styled.div`
//   border-radius: 50%;
//   height: 40px;
//   min-width: 40px;
//   color: #ffffff;
//   display: flex;
//   font-size: 30px;
//   margin-right: 13px;
//   align-items: center;
//   justify-content: center;
//   background-color: #008561;
//   cursor: pointer;
//   padding-left: 2px;

//   svg {
//     font-size: 18px;
//   }
// `;

const AddCarrierTypeDialog = ({
  isModalVisible,
  handleCloseDialog,
  shipment_type,
}) => {
  const companyLogoURL = SessionManagement.getCompanyLogoURL();
  const companyName = SessionManagement.getCompanyName();
  // State Management
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCarriers, setApiCarriers] = useState([]);
  const [carrierAccounts, setCarrierAccounts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [showRateCard, setShowRateCard] = useState(false);
  const [carrierForm, setCarrierForm] = useState({
    shipment_type: "",
    carrier: "",
    connection_type: "",
    calculation_connection_type: "",
    creation_connection_type: "",
    manual_zoning_type: "",
    transit_time: "",
    display_name: "",
    pricing_type: "",
    additional_info: "",
    rate_file_name: "",
    image_url: companyLogoURL,
    per_kg_countries: [
      {
        countries: [],
        countries_select: [],
        minimum_weight: "",
        maximum_weight: "",
        price: "",
        currency: "NGN",
      },
    ],
    shipping_plans: [],
  });
  const [logoFile, setLogoFile] = useState(companyName);
  const [rateDocFile, setRateDocFile] = useState("");
  const [uploadingRateDoc, setUploadingRateDoc] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [carrierShipOpt, setCarrierShipOpt] = useState([]);

  // End of State management

  const rateDocInputRef = useRef();

  // API calls

  const getCarrierAccounts = async (carrier_id) => {
    const formValues = {
      ...UserProfileData(),
      carrier: carrier_id,
    };
    setFetching(true);

    await axios
      .post(getApiCarrierAccountsUrl, formValues)
      .then((res) => {
        setFetching(false);
        if (res?.data?.success) {
          let carrier_accounts = res?.data?.data?.map((carrier) => {
            return {
              label: carrier?.name,
              value: carrier?.id,
            };
          });

          setCarrierAccounts(carrier_accounts);
        }
      })
      .catch((err) => {
        setFetching(false);
      });
  };

  const fetchApiCarriers = async () => {
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res.data.success) {
          if (shipment_type) {
            let api_carriers = res.data.data
              ?.filter(
                (carrier) => carrier[shipment_type?.toLowerCase()] === "Yes"
              )
              ?.map((carrier) => {
                return {
                  label: carrier.name,
                  value: carrier.id,
                  img_url: carrier.image_url,
                  multiple_shipment_plans: carrier.multiple_shipment_plans,
                };
              });

            setApiCarriers(api_carriers);
          }
        }
      })
      .catch((err) => {});
  };

  const fetchCarrierShippingPlans = async (api_carrier_id) => {
    const formValues = { ...UserProfileData(), api_carrier_id };

    await axios
      .post(getCarrierShippingPlansUrl, formValues)
      .then((res) => {
        if (res.data.success) {
          let shipping_options = res.data.data
            .filter(
              (ship_plan) => ship_plan[shipment_type?.toLowerCase()] === "Yes"
            )
            ?.map((ship_plan) => {
              return {
                label: ship_plan.name,
                value: ship_plan.name,
              };
            });

          setCarrierShipOpt(shipping_options);
        }
      })
      .catch((err) => {});
  };

  const uploadRateDocument = (file) => {
    const docFormData = new FormData();
    docFormData.append("document", file);

    if (!carrierForm.manual_zoning_type) {
      toast.error("Please select how your countries are zoned!");
      return;
    }

    const reader = new FileReader();

    let validate_document = false;

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);

      const trimmedArrayOfObjects = excelData.map(trimObjectKeys);
      console.log("ieoa", trimmedArrayOfObjects);

      if (carrierForm.manual_zoning_type === "Aramex") {
        validate_document = validateAramex(trimmedArrayOfObjects);
      } else if (carrierForm.manual_zoning_type === "DHL") {
        validate_document = validateDHL(trimmedArrayOfObjects);
      } else if (carrierForm.manual_zoning_type === "Fedex") {
        validate_document = validateFedex(trimmedArrayOfObjects);
      } else if (carrierForm.manual_zoning_type === "UPS") {
        validate_document = validateUPS(trimmedArrayOfObjects);
      } else {
        toast.error("Select a valid method your countries are zoned!");
        return;
      }
    };

    reader.readAsBinaryString(file);

    // Wrap remaining asynchronous logic in the onload event
    reader.onloadend = () => {
      if (validate_document) {
        setUploadingRateDoc(true);

        axios
          .post(uploadRateDocumentUrl, docFormData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            setUploadingRateDoc(false);
            setRateDocFile(file?.name);
            setCarrierForm({
              ...carrierForm,
              rate_file_name: response?.data,
            });
          })
          .catch(function (error) {
            console.error(error);
            return error;
          });
      }
    };
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formdata = {
      ...UserProfileData(),
      ...carrierForm,
      carrier: carrierForm?.api_type,
      pricing_type:
        carrierForm?.calculation_connection_type === "Custom"
          ? "Per KG"
          : "Default",
    };
    setIsSubmitting(true);

    axios
      .post(addCarrierTypeUrl, formdata)
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.success) {
          window.scrollTo(0, 0);

          setSuccessVisibility(true);
          setSuccessMessage("Carrier added successfully!");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(res?.data?.message);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  // End of API calls.

  // Handle side effects

  useEffect(() => {
    if (shipment_type) {
      setCarrierForm({
        ...carrierForm,
        shipment_type,
      });
      fetchApiCarriers();
    }
    // eslint-disable-next-line
  }, [shipment_type]);

  // End of side effects

  function trimObjectKeys(obj) {
    const trimmedObject = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const trimmedKey = key.trim();
        trimmedObject[trimmedKey] = obj[key];
      }
    }
    return trimmedObject;
  }

  const validateAramex = (data) => {
    if (data?.length < 1) {
      toast.error("Document is empty!");
      return false;
    } else {
      const requiredKeys = [
        "KG",
        "Zone 1",
        "Zone 2",
        "Zone 3",
        "Zone 4",
        "Zone 5",
        "Zone 6",
        "Zone 7",
        "Zone 8",
      ];
      let validate = requiredKeys.every((key) => key in data[0]);

      if (validate) {
        return validate;
      } else {
        toast.error("Please upload a valid document!");
        return validate;
      }
    }
  };

  const validateDHL = (data) => {
    if (data?.length < 1) {
      toast.error("Document is empty!");
      return false;
    } else {
      const requiredKeys = [
        "KG",
        "Zone 1",
        "Zone 2",
        "Zone 3",
        "Zone 4",
        "Zone 5",
        "Zone 6",
        "Zone 7",
        "Zone 8",
      ];
      let validate = requiredKeys.every((key) => key in data[0]);

      if (validate) {
        return validate;
      } else {
        toast.error("Please upload a valid document!");
        return validate;
      }
    }
  };

  const validateFedex = (data) => {
    if (data?.length < 1) {
      toast.error("Document is empty!");
      return false;
    } else {
      const requiredKeys = [
        "KG",
        "Zone A",
        "Zone B",
        "Zone C",
        "Zone D",
        "Zone E",
        "Zone F",
        "Zone G",
        "Zone H",
        "Zone I",
        "Zone J",
      ];
      let validate = requiredKeys.every((key) => key in data[0]);

      if (validate) {
        return validate;
      } else {
        toast.error("Please upload a valid document!");
        return validate;
      }
    }
  };

  const validateUPS = (data) => {
    if (data?.length < 1) {
      toast.error("Document is empty!");
      return false;
    } else {
      const requiredKeys = [
        "KG",
        "Zone 1",
        "Zone 2",
        "Zone 3",
        "Zone 4",
        "Zone 5",
        "Zone 6",
        "Zone 7",
        "Zone 8",
        "Zone 9",
      ];
      let validate = requiredKeys.every((key) => key in data[0]);

      if (validate) {
        return validate;
      } else {
        toast.error("Please upload a valid document!");
        return validate;
      }
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "api_type") {
      let api_type = apiCarriers?.find((api) => `${api?.value}` === value);
      setCarrierForm({
        ...carrierForm,
        [name]: value,
        image_url: api_type.img_url,
        multiple_shipment_plans: api_type.multiple_shipment_plans,
      });
      setLogoFile(apiCarriers?.find((api) => `${api?.value}` === value)?.label);
      getCarrierAccounts(value);
      fetchCarrierShippingPlans(value);
    } else {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
      });
    }
  };

  const triggerRateDocInput = () => {
    rateDocInputRef.current.click();
  };

  const handleRateDocInput = (e) => {
    const { files } = e.target;
    console.log("oal");
    uploadRateDocument(files[0]);
  };

  const calculate_connection_type_options = [{ label: "API", value: "API" }];

  if (shipment_type !== "Interstate") {
    calculate_connection_type_options.push(
      { label: "Per KG", value: "Custom" },
      { label: "Using Excel Spreadsheet", value: "Manual" }
    );
  }

  const create_connection_type_options = [
    { label: "Custom", value: "Custom" },
    { label: "API", value: "API" },
  ];

  const country_options = countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  });

  const currency_options = currencies
    .filter(
      (currency) =>
        currency.cc === "USD" ||
        currency.cc === "NGN" ||
        currency.cc === "GBP" ||
        currency.cc === "EUR"
    )
    ?.map((currency) => {
      return {
        label: `${currency.name} - ${currency.cc}`,
        value: currency.cc,
      };
    });

  const handleWeightRateInput = (e, id) => {
    const { name, value } = e.target;
    const per_kg_copy = [...carrierForm.per_kg_countries];

    per_kg_copy[id] = {
      ...per_kg_copy[id],
      [name]: value,
    };

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const handleSelectCountryInput = (id, value) => {
    const per_kg_copy = [...carrierForm.per_kg_countries];

    let countries_map = value?.map((country) => country?.value);
    let countries = countries_map?.join(",");

    per_kg_copy[id] = {
      ...per_kg_copy[id],
      countries: countries,
      countries_select: value,
    };

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const handleUploadLogo = (url) => {
    setCarrierForm({
      ...carrierForm,
      image_url: url,
    });
  };

  const zoning_options = [
    { label: "Aramex Country Zoning", value: "Aramex" },
    { label: "DHL Country Zoning", value: "DHL" },
    { label: "FedEx Country Zoning", value: "Fedex" },
    { label: "UPS Country Zoning", value: "UPS" },
  ];

  const openRateCards = () => {
    setShowRateCard(true);
  };

  const closeRateCards = () => {
    setShowRateCard(false);
  };

  const checkIfMultiCarriers = () => {
    // This function checks if selected API allows multiple carriers.

    // Conditions to be met:
    // 1. Check if calculate connection type is API
    // 2. Check if the selected API returns multiple sub carrier rates.

    let check = false;

    if (carrierForm.calculation_connection_type === "API") {
      if (carrierForm?.multiple_shipment_plans === "Yes") {
        check = true;
      }
    }

    return check;
  };

  const handleSelectCarrierOptions = (value) => {
    // Update selected_shipping_options field which adjusts shipping_plans field.
    // Checks involved:
    // 1. If carrier is removed: remove option with similar name from shipping_plans.
    // 2. If carrier is added: append option to shipping_plans with necessary fields.
    // Steps involved includes:
    // 1. update selected_shipping_options
    // 2. perform check from checks above

    let selected_shipping_options = value;

    let shipping_plans = [...carrierForm?.shipping_plans];

    if (shipping_plans.length > 0) {
      let value_index = shipping_plans.findIndex(
        (plan) => plan["3pl_plan_name"] === value.value
      );
      if (value_index === -1) {
        let update = {
          transit_time: "",
          display_name: "",
          additional_info: "",
          image_url: carrierForm.image_url,
          minimum_weight: "",
          maximum_weight: "",
        };
        update["3pl_plan_name"] = value[value?.length - 1]?.value;

        shipping_plans.push(update);
      } else {
        shipping_plans.splice(value_index, 1);
      }
    } else {
      let update = {
        transit_time: "",
        display_name: "",
        additional_info: "",
        image_url: carrierForm.image_url,
        minimum_weight: "",
        maximum_weight: "",
      };
      update["3pl_plan_name"] = value[0]?.value;

      shipping_plans.push(update);
    }

    setCarrierForm({
      ...carrierForm,
      shipping_plans: shipping_plans,
      selected_shipping_options,
    });
  };

  const handleMultiPlanInput = (e, id) => {
    // goal: update input fields relating to multi carrier plans
    // steps:
    // 1. get the id, value and name of the focused input.
    // 2. create a copy of the index object within the shipping_plans list.
    // 3. update the object and replace the current list with the modified copied list.

    const { name, value } = e.target;

    let shipping_plans_copy = [...carrierForm?.shipping_plans];

    shipping_plans_copy[id] = {
      ...shipping_plans_copy[id],
      [name]: value,
    };

    setCarrierForm({
      ...carrierForm,
      shipping_plans: [...shipping_plans_copy],
    });
  };

  const handleMultiUploadLogo = (url, id) => {
    // goal: update image for each carrier plans for multi carriers.

    let shipping_plans_copy = [...carrierForm?.shipping_plans];

    let updated_obj = {
      ...shipping_plans_copy[id],
      image_url: url,
    };

    shipping_plans_copy[id] = updated_obj;

    setCarrierForm({
      ...carrierForm,
      shipping_plans: [...shipping_plans_copy],
    });
  };

  return (
    <Modal size="lg" show={isModalVisible} onHide={handleCloseDialog}>
      <RatesDocumentDialog
        isModalVisible={showRateCard}
        handleCloseDialog={closeRateCards}
      />
      <Modal.Header>
        <h5 className="header">ADD NEW CARRIER ({shipment_type})</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add a carrier type</p>
        <div className="query-result-container">
          <Row className="mb-2 gy-4">
            {/* How to calculate and create rate */}
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  How do you want your rates calculated?
                </label>
                <SelectBox
                  name="calculation_connection_type"
                  value={carrierForm?.calculation_connection_type}
                  options={calculate_connection_type_options}
                  onChange={handleInput}
                  placeholder="Enter connection type"
                />
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  How should shipments be created?
                </label>
                <SelectBox
                  name="creation_connection_type"
                  value={carrierForm?.creation_connection_type}
                  options={create_connection_type_options}
                  onChange={handleInput}
                  placeholder="Enter connection type"
                />
              </div>
            </Col>

            {/* If selection is API */}
            {(carrierForm?.creation_connection_type === "API" ||
              carrierForm?.calculation_connection_type === "API") && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    Select API to use
                  </label>
                  <SelectBox
                    name="api_type"
                    value={carrierForm?.api_type}
                    options={apiCarriers}
                    onChange={handleInput}
                    placeholder="Select API"
                  />
                </div>
              </Col>
            )}
            {(carrierForm?.creation_connection_type === "API" ||
              carrierForm?.calculation_connection_type === "API") && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    Select API account to use
                  </label>
                  <div className="d-flex gap-2 align-items-center">
                    {fetching && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}
                    <SelectBox
                      name="api_account_id"
                      value={carrierForm?.api_account_id}
                      options={carrierAccounts}
                      onChange={handleInput}
                      placeholder="Select api account"
                    />
                  </div>
                </div>
              </Col>
            )}

            {checkIfMultiCarriers() && (
              <Row className="mx-0 p-0 gy-4 my-4">
                <Col xs={12}>
                  <div>
                    <label htmlFor="Description" className="mb-1">
                      Select shipping plans to use
                    </label>
                    <MultiSelectBox
                      name="selected_shipping_options"
                      value={carrierForm?.selected_shipping_options}
                      options={carrierShipOpt}
                      onChange={(value) => handleSelectCarrierOptions(value)}
                      placeholder="Select countries"
                    />
                  </div>
                </Col>
                {carrierForm.shipping_plans?.map((plan, id) => (
                  <Row className="m-0 p-0 gy-4">
                    <Col xs={12} lg={6}>
                      <div className="">
                        <label htmlFor="Description" className="mb-1">
                          Plan Name
                        </label>
                        <TextBox
                          type="text"
                          name="3pl_plan_name"
                          value={plan["3pl_plan_name"]}
                          onChange={(e) => handleMultiPlanInput(e, id)}
                          disabled={true}
                          placeholder="Enter carrier display name"
                        />
                      </div>
                    </Col>

                    <Col xs={12} lg={6}>
                      <div className="">
                        <label htmlFor="Description" className="mb-1">
                          Provide a custom name for this plan
                        </label>
                        <TextBox
                          type="text"
                          name="display_name"
                          value={plan?.display_name}
                          onChange={(e) => handleMultiPlanInput(e, id)}
                          placeholder="Enter carrier display name"
                        />
                      </div>
                    </Col>

                    <Col xs={12} lg={6}>
                      <div>
                        <label className="mb-1" htmlFor="Description">
                          Transit Time
                        </label>
                        <TextBox
                          name="transit_time"
                          type="text"
                          value={plan.transit_time}
                          onChange={(e) => handleMultiPlanInput(e, id)}
                          placeholder="E.g: 2 to 3 working days"
                        />
                      </div>
                    </Col>

                    <Col xs={12} lg={6}>
                      <div className="">
                        <label htmlFor="package img" className="mb-0">
                          Upload Carrier Logo
                        </label>
                        <UploadImgField
                          handleInput={handleMultiUploadLogo}
                          fileName={plan["3pl_plan_name"]}
                          setFileName={() => {}}
                          imageUrl={plan?.image_url}
                          id={id}
                        />
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className="mb-2">
                        <label htmlFor="Description" className="mb-1">
                          Additional Information
                        </label>
                        <TextFieldBox
                          name="additional_info"
                          value={plan?.additional_info}
                          onChange={(e) => handleMultiPlanInput(e, id)}
                          placeholder="Enter additional information"
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
              </Row>
            )}

            {/* For Display name and transit name for non multi shipments */}
            {!checkIfMultiCarriers() && (
              <Row className="my-4 mx-0 p-0">
                <Col xs={12} lg={8}>
                  <div className="">
                    <label htmlFor="Description" className="mb-1">
                      Display Name
                    </label>
                    <TextBox
                      type="text"
                      name="display_name"
                      value={carrierForm.display_name}
                      onChange={handleInput}
                      placeholder="Enter carrier display name"
                    />
                  </div>
                </Col>

                <Col xs={12} lg={4}>
                  <div>
                    <label className="mb-1" htmlFor="Description">
                      Transit Time
                    </label>
                    <TextBox
                      name="transit_time"
                      type="text"
                      value={carrierForm.transit_time}
                      onChange={handleInput}
                      placeholder="E.g: 2 to 3 working days"
                    />
                  </div>
                </Col>
              </Row>
            )}

            {/* If calculation connection type is manual */}
            {carrierForm.calculation_connection_type === "Manual" && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    How were the countries zoned?
                  </label>
                  <SelectBox
                    name="manual_zoning_type"
                    value={carrierForm?.manual_zoning_type}
                    options={zoning_options}
                    onChange={handleInput}
                    placeholder="Enter zoning type"
                  />
                </div>
              </Col>
            )}

            {carrierForm.calculation_connection_type === "Manual" && (
              <Col md={6}>
                <div className="">
                  <div className="d-flex gap-2">
                    <label className="" htmlFor="package img">
                      Upload carrier rates document
                    </label>
                    <span style={{ marginTop: "-3px" }} onClick={openRateCards}>
                      <Tooltip
                        title="Click here to document samples"
                        placement="top"
                      >
                        <span>
                          <IoMdHelpCircle />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    {uploadingRateDoc && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}
                    <div className="w-100">
                      <input
                        style={{
                          visibility: "hidden",
                          position: "fixed",
                          bottom: 0,
                          zIndex: -1,
                        }}
                        type="file"
                        onChange={handleRateDocInput}
                        ref={rateDocInputRef}
                        accept=".xlsx, .xls"
                        name=""
                        id=""
                      />
                      <div
                        style={{ marginTop: 11 }}
                        onClick={triggerRateDocInput}
                        className="change-password-button"
                      >
                        Choose Spreadsheet
                      </div>
                    </div>
                  </div>
                  {carrierForm?.rate_file_name !== "" && (
                    <small>{rateDocFile}</small>
                  )}
                </div>
              </Col>
            )}

            {/* If calculation type is custom */}
            {carrierForm?.calculation_connection_type === "Custom" && (
              <Col xs={12}>
                {carrierForm.per_kg_countries?.map((kg_item, id) => (
                  <Row key={id} className="gy-3">
                    <Col xs={12}>
                      <div>
                        <label className="mb-2" htmlFor="Description">
                          Country
                        </label>
                        <MultiSelectBox
                          name="countries"
                          value={kg_item?.countries_select}
                          options={country_options}
                          onChange={(value) =>
                            handleSelectCountryInput(id, value)
                          }
                          id={id}
                          placeholder="Select countries"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={3}>
                      <div>
                        <label className="mb-1" htmlFor="Description">
                          Minimum Weight
                        </label>
                        <TextBox
                          name="minimum_weight"
                          type="text"
                          value={kg_item?.minimum_weight}
                          onChange={(e) => handleWeightRateInput(e, id)}
                          placeholder="KG"
                          prefix="KG"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={3}>
                      <div>
                        <label className="mb-1" htmlFor="Description">
                          Maximum Weight
                        </label>
                        <TextBox
                          name="maximum_weight"
                          type="text"
                          value={kg_item?.maximum_weight}
                          onChange={(e) => handleWeightRateInput(e, id)}
                          placeholder="KG"
                          prefix="KG"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={3}>
                      <div className="">
                        <label className="mb-1" htmlFor="Description">
                          Currency
                        </label>
                        <SelectBox
                          name="currency"
                          value={kg_item?.currency}
                          onChange={(e) => handleWeightRateInput(e, id)}
                          placeholder="Select currency"
                          options={currency_options}
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={3}>
                      <div className="">
                        <label className="mb-1" htmlFor="Description">
                          Price
                        </label>
                        <TextBox
                          name="price"
                          type="text"
                          value={kg_item?.price}
                          onChange={(e) => handleWeightRateInput(e, id)}
                          prefix={kg_item?.currency ?? ""}
                          placeholder="Enter fee"
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
              </Col>
            )}

            {/* Upload logo and provide additional information for non multi shipments */}

            {!checkIfMultiCarriers() && (
              <Row className="mx-0 my-4 p-0 gy-4">
                <Col xs={12}>
                  <div className="">
                    <label htmlFor="package img" className="mb-0">
                      Upload Carrier Logo
                    </label>
                    <UploadImgField
                      handleInput={handleUploadLogo}
                      fileName={logoFile}
                      setFileName={setLogoFile}
                      imageUrl={carrierForm?.image_url}
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="mb-2">
                    <label htmlFor="Description" className="mb-1">
                      Additional Information
                    </label>
                    <TextFieldBox
                      name="additional_info"
                      value={carrierForm.additional_info}
                      onChange={handleInput}
                      placeholder="Enter additional information"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={onSubmit} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCarrierTypeDialog;
