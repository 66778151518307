import "../../styles/home.scss";
import "../../styles/single-shipment.scss";
import "../../styles/components.scss";
import "../../styles/create-admin.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import axios from "axios";
import { createTransporterURL, uploadImageURL } from "../../library/URLs";
import { UserProfileData } from "../../library/constants";
import { useNavigate } from "react-router-dom";
import SubscriptionInactive from "../../components/SubscriptionInactive";
import { FaTruck } from "react-icons/fa";
import {
  PasswordBox,
  PhoneFieldBox,
  TextBox,
} from "../../components/InputFields";

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const CreateTransporter = () => {
  const navigator = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    phone_number: "",
    password: "",
    confirm_password: "",
  });

  const [isCreatingAdmin, setIsCreatingTransporter] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const form = useRef(null);

  const createNewTransporter = (profileImageURL) => {
    const formValues = {...formData};
    formValues["profile_image_url"] = profileImageURL;

    const newData = { ...formValues, ...UserProfileData() };

    axios
      .post(createTransporterURL, newData)
      .then((response) => {
        console.log(response);
        setIsCreatingTransporter(false);
        window.scrollTo(0, 0);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Successfully created transporter. ");

          setTimeout(() => {
            navigator("/transporters");
          }, 3000);
        } else {
          const message = response["data"]["message"];

          setErrorVisibility(true);
          setErrorMessage(message);
          setTimeout(() => {
            setErrorVisibility(false);
          }, 3000);
        }
      })
      .catch((error) => console.log(error));
  };

  function showError(message) {
    window.scrollTo(0, 0);
    setErrorVisibility(true);
    setErrorMessage(message);

    setTimeout(() => {
      setErrorVisibility(false);
    }, 3000);
  }

  const uploadProfileImage = () => {
    const fileInput = document.querySelector("#profile-image");

    if (formData.name === "") {
      showError("Please enter valid name");
      return false;
    }

    if (formData.username === "") {
      showError("Please enter valid username");
      return false;
    }

    if (formData.phone_number === "") {
      showError("Please enter valid phone number");
      return false;
    }

    if (formData.password === "") {
      showError("Please enter valid password");
      return false;
    }

    if (formData.confirm_password !== formData.password) {
      showError("Please make sure your password are correct");
      return false;
    }

    if (fileInput.files[0] === undefined) {
      showError("Please upload a profile picture");
      return false;
    }

    const formValues = {};
    formValues["document"] = fileInput.files[0];

    setIsCreatingTransporter(true);

    axios
      .post(uploadImageURL, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const profileImageURL = response["data"];

        createNewTransporter(profileImageURL);
      })
      .catch((error) => console.log(error));
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhone = (code) => {
    setFormData({
      ...formData,
      phone_number: code,
    });
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div
          className="page pb-5"
          style={{ maxWidth: "100vw", overflow: "hidden" }}
        >
          <SubscriptionInactive />

          {/* <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Create Transporter</h5>
                        <span>Home</span>
                    </div> */}
          <div className="container-fluid">
            <div className="card border-0 p-4">
              <div className="d-flex align-items-center mb-4 flex-row gap-2 ">
                {/* <img src={AdminIcon} alt="group" /> */}
                <FaTruck style={{ fontSize: 24, color: "#98a2b3" }} />
                <h5 className="m-0">Transporters</h5>
              </div>
              <div className="">
                {isError === false ? null : (
                  <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                  <SuccessMessageContainer>
                    {successMessage}
                  </SuccessMessageContainer>
                )}

                <form ref={form}>
                  <section>
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>1</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Basic Details
                      </SteppedTitle>
                    </div>
                    <Row className="form-row">
                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Full Name">Full Name</label>
                        <TextBox
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleInput}
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Email">Username</label>
                        <TextBox
                          id="username"
                          name="username"
                          type="text"
                          value={formData.username}
                          onChange={handleInput}
                          placeholder=""
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Phone Number">Phone Number</label>
                        <PhoneFieldBox
                          id="phone"
                          value={formData.phone_number}
                          onChange={handlePhone}
                          name="phone_number"
                          placeholder=""
                        />
                      </Col>
                    </Row>
                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Full Name">Password</label>
                        <PasswordBox
                          id="password"
                          value={formData.password}
                          onChange={handleInput}
                          name="password"
                        />
                      </Col>

                      <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Phone Number">Confirm Password</label>
                        <PasswordBox
                          id="confirm_password"
                          name="confirm_password"
                          value={formData.confirm_password}
                          onChange={handleInput}
                        />
                      </Col>
                    </Row>
                  </section>

                  <section className="mt-5">
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>2</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Profile Image
                      </SteppedTitle>
                    </div>
                    <input
                      type="file"
                      id="profile-image"
                      className="form-control-file"
                    />
                  </section>

                  <div className="d-flex flex-row my-5 justify-content-end">
                    <SubmitButton
                      onClick={(e) => uploadProfileImage()}
                      type="button"
                      className="confirm-button btn-primary"
                    >
                      {isCreatingAdmin === false ? (
                        <span className="button-text">CREATE TRANSPORTER</span>
                      ) : (
                        <span className="spinner-border spinner-grow-sm" />
                      )}
                    </SubmitButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTransporter;
