import React, { useEffect, useState } from "react";
import { BsChevronDoubleRight } from "react-icons/bs";
import SenderIcon from "../../../assets/create-shipment/sender.svg";
import SenderActiveIcon from "../../../assets/create-shipment/sender-active.svg";
import ReceiverIcon from "../../../assets/create-shipment/receiver.svg";
import ReceiverActiveIcon from "../../../assets/create-shipment/receiver-active.svg";
import PackageIcon from "../../../assets/create-shipment/package.svg";
import PackageActiveIcon from "../../../assets/create-shipment/package-active.svg";
import PaymentIcon from "../../../assets/create-shipment/payment.svg";
import PaymentActiveIcon from "../../../assets/create-shipment/payment-active.svg";
import ConfirmIcon from "../../../assets/create-shipment/confirm.svg";
import ConfirmActiveIcon from "../../../assets/create-shipment/confirm-active.svg";
import Sender from "./Sender";
import Receiver from "./Receiver";
import Package from "./Package";
import Payment from "./Payment";
import Confirm from "./Confirm";
import countries from "../../../files/countries.json";

const InternationalShipment = ({ shipmentInfo }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const [formData, setFormData] = useState({
    sender_details: {
      fullname: "",
      email: "",
      phone: "",
      address_1: "",
      address_2: "",
      country: "Nigeria",
      state: "",
      city: "",
      postal_code: "",
      map_locator: "",
      coordinates: "",
    },
    receiver_details: {
      fullname: "",
      email: "",
      phone: "",
      address_1: "",
      address_2: "",
      country: "",
      state: "",
      city: "",
      postal_code: "",
      map_locator: "",
      coordinates: "",
    },
    packages: [
      {
        number_of_items: 0,
        package_value: 0,
        package_weight: 0,
        package_length: "1",
        package_width: "1",
        package_height: "1",
        description: "Package",
        package_items: [],
      },
    ],
    carrier_detail: {},
    amount_pickup: "",
    amount: "",
    pickup: "No",
    pickup_fee: 0,
    insurance: "No",
    insurance_fee: 0,
    payment_method: "",
    transporter: "",
    additional_services: [],
    term: false,
  });

  useEffect(() => {

    if(shipmentInfo) {
      let sender_country = shipmentInfo?.sender_country?.length < 3 ? 
      countries?.find(country => country.code === shipmentInfo?.sender_country)?.name : shipmentInfo?.sender_country;

      let receiver_country = shipmentInfo?.receiver_country?.length < 3 ? 
      countries?.find(country => country.code === shipmentInfo?.receiver_country)?.name : shipmentInfo?.receiver_country;

      let recreate_shipment = {
        ...formData,
        sender_details: {
          ...formData.sender_details,
          fullname: shipmentInfo?.sender_name,
          email: shipmentInfo?.sender_email,
          phone: shipmentInfo?.sender_phone_no,
          address_1: shipmentInfo?.sender_address,
          address_2: shipmentInfo?.sender_address_2,
          country: sender_country,
          state: shipmentInfo?.sender_state,
          city: shipmentInfo?.sender_city,
          postal_code: shipmentInfo?.sender_postcode,
        },
        receiver_details: {
          ...formData?.receiver_details,
          fullname: shipmentInfo?.recipient_name,
          email: shipmentInfo?.recipient_email,
          phone: shipmentInfo?.recipient_phone_no,
          address_1: shipmentInfo?.receiver_address,
          address_2: shipmentInfo?.receiver_address_2,
          country: receiver_country,
          state: shipmentInfo?.receiver_state,
          city: shipmentInfo?.receiver_city,
          postal_code: shipmentInfo?.receiver_postcode,
        },
        packages: shipmentInfo?.packages
      };

      setFormData(recreate_shipment);
    };
    // eslint-disable-next-line
  }, [shipmentInfo]);

  const nextPage = () => {
    if (currentPage < 4) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleTabSelect = (id) => {
    if(currentPage > id) {
      setCurrentPage(id)
    }
  };

  return (
    <div>
      <ul className="create-shipment-form-tabs">
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 0 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 0 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(0)}
        >
          {currentPage === 0 ? (
            <img src={SenderActiveIcon} alt="icon" />
          ) : (
            <img src={SenderIcon} alt="icon" />
          )}
          Sender
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 0 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 1 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 1 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(1)}
        >
          {currentPage === 1 ? (
            <img src={ReceiverActiveIcon} alt="icon" />
          ) : (
            <img src={ReceiverIcon} alt="icon" />
          )}
          Receiver
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 1 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 2 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 2 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(2)}
        >
          {currentPage === 2 ? (
            <img src={PackageActiveIcon} alt="icon" />
          ) : (
            <img src={PackageIcon} alt="icon" />
          )}
          Package
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 2 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 3 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 3 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(3)}
        >
          {currentPage === 3 ? (
            <img src={PaymentActiveIcon} alt="icon" />
          ) : (
            <img src={PaymentIcon} alt="icon" />
          )}
          Pricing & Payment
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 3 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 4 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 4 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(4)}
        >
          {currentPage === 4 ? (
            <img src={ConfirmActiveIcon} alt="icon" />
          ) : (
            <img src={ConfirmIcon} alt="icon" />
          )}
          Confirm
        </li>
      </ul>
      <div>
        {currentPage === 0 ? (
          <Sender
            nextPage={nextPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : currentPage === 1 ? (
          <Receiver
            nextPage={nextPage}
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : currentPage === 2 ? (
          <Package
            nextPage={nextPage}
            setCarriers={setCarriers}
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : currentPage === 3 ? (
          <Payment
            nextPage={nextPage}
            carriers={carriers}
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : currentPage === 4 ? (
          <Confirm
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default InternationalShipment;
