import React, { useState } from "react";
import ConfirmIcon from "../../../assets/create-shipment/payment-page.svg";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import { BsChevronDown, BsChevronUp, BsDot } from "react-icons/bs";
import { createInterShipmentsUrl, createNewInterShipmentsUrl } from "../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
import { fetchPackageTotalValue, fetchPackageTotalWeight } from "../Utilities";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Confirm = ({ previousPage, formData }) => {
  const [showPackages, setShowPackages] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [term, setTerm] = useState(false);
  const navigate = useNavigate();

  // handle package list toggle
  const handleShowPackages = (id) => {
    if (showPackages === id) {
      setShowPackages(null);
    } else {
      setShowPackages(id);
    }
  };

  let additional_services_fee = 0;
  formData?.additional_services?.forEach((serv) => {
    additional_services_fee += parseFloat(serv?.fee);
  });

  let cleaned_amount = parseFloat(`${formData?.amount}`?.replaceAll(",", ""))
  let amount = parseFloat(formData?.pickup_fee) + parseFloat(formData?.insurance_fee) + parseFloat(additional_services_fee) + cleaned_amount;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!term) {
      toast.error("Click the checkbox to confirm your shipment!");
      return;
    }

    let packageWeight = fetchPackageTotalWeight(formData?.packages);
    let packageValue = fetchPackageTotalValue(formData?.packages);

    const data = {
      sender_name: formData?.sender_details?.fullname,
      terminal_rate_id: formData?.carrier?.rate_id ?? "",
      terminal_parcel_id: formData?.carrier?.parcel_id ?? "",
      terminal_pickup_id: formData?.carrier?.pickup_address_id ?? "",
      terminal_delivery_id: formData?.carrier?.delivery_address_id ?? "",
      carrier: formData?.carrier?.carrier_id,
      sender_phone: formData?.sender_details?.phone,
      sender_email: formData?.sender_details?.email,
      sender_address: formData?.sender_details?.address_1,
      sender_postal_code: formData?.sender_details?.postal_code,
      sender_city: formData?.sender_details?.city,
      sender_county: formData?.sender_details?.state,
      sender_country: formData?.sender_details?.country,
      sender_address_2: formData?.sender_details?.address_2,
      receiver_name: formData?.receiver_details?.fullname,
      receiver_phone: formData?.receiver_details?.phone,
      receiver_email: formData?.receiver_details?.email,
      receiver_address: formData?.receiver_details?.address_1,
      receiver_address_2: formData?.receiver_details?.address_2,
      receiver_city: formData?.receiver_details?.city,
      receiver_county: formData?.receiver_details?.state,
      receiver_country: formData?.receiver_details?.country,
      receiver_postal_code: formData?.receiver_details?.postal_code,
      packages: formData?.packages,
      weight: packageWeight,
      length: formData?.packages[0]?.package_length,
      breadth: formData?.packages[0]?.package_width,
      height: formData?.packages[0]?.package_height,
      description: formData?.packages[0]?.package_items[0]?.description,
      parcel_type: "Parcel",
      shipping_date: moment(formData?.shipping_date).format("MM/DD/YYYY"),
      insurance: formData?.insurance,
      requested_pickup: formData?.pickup,
      pickup_fee: formData?.pickup_fee,
      insurance_fee: formData?.insurance_fee,
      declared_value: packageValue,
      carrier_cost_price: formData?.cost_price,
      amount: amount,
      payment_method: formData?.payment_method,
      pickup_detail_id: formData?.pickup_station,
      additional_services: formData?.additional_services?.map((sv) => sv?.id)
    };

    setIsSubmitting(true);

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(createNewInterShipmentsUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsSubmitting(false);
        
        if(response["data"]["success"]) {
          let shipmentID = response["data"]["data"]["shipment_id"];
          window.scrollTo(0, 0);

          toast.success(response["data"]["data"]["message"]);
          setTerm(false);
          setTimeout(() => {
            navigate("/shipment/" + shipmentID);
          }, 2000);
        } else {
          toast.error(response["data"]["message"]);
        }
      });

    console.log("interstate log ", formData);
  };

  console.log("afa ", formData);

  return (
    <div className="create-shipment-grid">
      <div className="create-shipment-info">
        <img src={ConfirmIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Confirm Details</p>
            <p className="create-shipment-info-sub">
              Confirm details accuracy before proceeding
            </p>
          </div>
        </div>
      </div>
      <div className="create-shipment-info-form">
        <div className="confirm-details-wrapper">
          <div className="confirm-details">
            <p className="confirm-details-title">Shipment Summary</p>
            <div className="confirm-details-address">
              {/* Sender Info */}
              <div className="confirm-details-address-option">
                <p className="confirm-details-address-option-text-1">
                  From (sender details)
                </p>
                <div>
                  <p className="confirm-details-address-option-text-2 text-end">
                    {`${formData.sender_details?.fullname}`}
                  </p>
                  <p className="confirm-details-address-option-text-1 text-end">
                    {formData.sender_details.address_1}{" "}
                  </p>
                </div>
              </div>
              {/* Receiver Info */}
              <div className="confirm-details-address-option">
                <p className="confirm-details-address-option-text-1">
                  To (receiver details)
                </p>
                <div>
                  <p className="confirm-details-address-option-text-2 text-end">
                    {`${formData.receiver_details?.fullname}`}
                  </p>
                  <p className="confirm-details-address-option-text-1 text-end">
                    {formData.receiver_details.address_1}{" "}
                  </p>
                </div>
              </div>
              <div className="confirm-details-address-icon">
                <HiOutlineSwitchVertical />
              </div>
            </div>
            <div className="confirm-details-packages">
              {formData?.packages?.map((pck, id) => (
                <div
                  className="confirm-details-packages-package"
                  key={id}
                  onClick={() => handleShowPackages(id)}
                >
                  <div className="confirm-details-packages-package-info">
                    <div className="confirm-details-packages-package-info-wrap">
                      <p className="confirm-details-packages-package-info-title">
                        Package {id + 1}
                      </p>
                      <BsDot />
                      <p className="confirm-details-packages-package-info-values">
                        {pck?.number_of_items} Item(s)
                      </p>
                      <BsDot />
                      <p className="confirm-details-packages-package-info-values">
                        {pck?.package_weight} KG
                      </p>
                      <BsDot />
                      <p className="confirm-details-packages-package-info-values">
                        {"₦"} {pck?.package_value}
                      </p>
                      <BsDot />
                      <p className="confirm-details-packages-package-info-values">
                        {pck?.package_length}{" "}
                        <span className="text-muted">L</span>
                      </p>
                      <BsDot />
                      <p className="confirm-details-packages-package-info-values">
                        {pck?.package_width}{" "}
                        <span className="text-muted">B</span>
                      </p>
                      <BsDot />
                      <p className="confirm-details-packages-package-info-values">
                        {pck?.package_height}{" "}
                        <span className="text-muted">H</span>
                      </p>
                    </div>
                    {id === showPackages ? (
                      <BsChevronUp className="text-black" />
                    ) : (
                      <BsChevronDown className="text-black" />
                    )}
                  </div>
                  <div
                    style={{
                      overflow: "hidden",
                      maxHeight: id === showPackages ? "1000px" : 0,
                      transition: "all 500ms ease-in-out",
                    }}
                  >
                    <div className="confirm-details-packages-items">
                      <ul>
                        <li>
                          <p className="confirm-details-packages-items-label">
                            Items
                          </p>
                          <p className="confirm-details-packages-items-label">
                            Quantity
                          </p>
                        </li>
                        {pck.package_items?.map((pckItem, index) => (
                          <li key={index}>
                            <p className="confirm-details-packages-items-category">
                              {pckItem?.category}
                            </p>
                            <p className="confirm-details-packages-items-quantity">
                              {pckItem?.quantity}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <div className="confirm-details-payment">
                <div className="confirm-details-payment-option">
                  <p className="confirm-details-payment-option-text-1">
                    Payment Method
                  </p>
                  <p className="confirm-details-payment-option-text-2">
                    {formData.payment_method}
                  </p>
                </div>
                <div className="confirm-details-payment-option">
                  <p className="confirm-details-payment-option-text-1">
                    Station
                  </p>
                  <p className="confirm-details-payment-option-text-2">
                    {
                      formData?.stations?.find(
                        (station) =>
                          station.value === parseInt(formData?.pickup_station)
                      )?.label
                    }
                  </p>
                </div>
              </div>
              <div className="confirm-summary">
                <p className="confirm-summary-title">Package Summary</p>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Insurance Fee</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      &#8358; {parseFloat(formData?.insurance_fee)?.toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Additional Services Fee</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      &#8358; {parseFloat(additional_services_fee)?.toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Pickup Fee</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      &#8358; {parseFloat(formData?.pickup_fee)?.toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Selling Price</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      &#8358; {parseFloat(formData?.amount)?.toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Shipment Cost</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      &#8358; {parseFloat(amount)?.toLocaleString("en-US")}
                    </p>
                    <p className="confirm-summary-cost-text-3">Inc. VAT</p>
                  </div>
                </div>
                <div className="confirm-summary-term">
                  <input type="checkbox" checked={term} onChange={()=>setTerm(!term)} name="" id="" />
                  <p>
                    I accept that the declaration above is a proper and accurate
                    description of the contents of my package.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
