import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import SubscriptionInactive from '../components/SubscriptionInactive'
import AdminIcon from "../assets/admins/group.svg";
import { Col, Row } from 'react-bootstrap';
import "../styles/wallet.scss";
import ReactApexChart from "react-apexcharts";
import EMVChip from "../assets/wallet/emv-chip.png";
import Pattern from "../assets/wallet/rb_2016.png";
import { FaCopy } from 'react-icons/fa';
import EmptyScreen from '../components/EmptyScreen';
import { GoPrimitiveDot } from 'react-icons/go';
import moment from 'moment';
import Pagination from "../components/Pagination";
import TransactionIcon from "../assets/sidebar/Transactions.svg";

const transactions_data =  [
  {
      "id": 3375,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Queen Queen",
      "identifier": "Customer-6806",
      "amount": "60000",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000003241204085331003589409210",
      "transaction_id": "",
      "date_time": "2024-12-04 08:55:03",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Queen Queen",
      "formatted_date": "Wednesday December 4, 8:55 AM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3369,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for VectorSpace Limited",
      "identifier": "Customer-7889",
      "amount": "61500",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000016241203194934000026633659",
      "transaction_id": "",
      "date_time": "2024-12-03 20:30:38",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "VectorSpace Limited",
      "formatted_date": "Tuesday December 3, 8:30 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3365,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Alhaja Sephia",
      "identifier": "Customer-6782",
      "amount": "2000",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000014241203171759236769607643",
      "transaction_id": "",
      "date_time": "2024-12-03 17:18:38",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Alhaja Sephia",
      "formatted_date": "Tuesday December 3, 5:18 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3364,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Feeky Clothing",
      "identifier": "Customer-7747",
      "amount": "260000",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000013241203171519000009538289",
      "transaction_id": "",
      "date_time": "2024-12-03 17:15:32",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Feeky Clothing",
      "formatted_date": "Tuesday December 3, 5:15 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3359,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Alhaja Sephia",
      "identifier": "Customer-6782",
      "amount": "13500",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000014241203163122248938802649",
      "transaction_id": "",
      "date_time": "2024-12-03 16:32:04",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Alhaja Sephia",
      "formatted_date": "Tuesday December 3, 4:32 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3358,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Omowumi Sowemimo",
      "identifier": "Customer-6759",
      "amount": "225000",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000026241203162417000112427685",
      "transaction_id": "",
      "date_time": "2024-12-03 16:26:47",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Omowumi Sowemimo",
      "formatted_date": "Tuesday December 3, 4:26 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3355,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Nikki Odiete",
      "identifier": "Customer-6680",
      "amount": "75000",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000014241203150106253912341871",
      "transaction_id": "",
      "date_time": "2024-12-03 15:02:17",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Nikki Odiete",
      "formatted_date": "Tuesday December 3, 3:02 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3354,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Abisola Adebesin",
      "identifier": "Customer-6693",
      "amount": "1500",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000023241203145829008132323281",
      "transaction_id": "",
      "date_time": "2024-12-03 14:59:54",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Abisola Adebesin",
      "formatted_date": "Tuesday December 3, 2:59 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3353,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Ifeanyi Okechukwu",
      "identifier": "Customer-6970",
      "amount": "16400",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "090267241203145550965100414197",
      "transaction_id": "",
      "date_time": "2024-12-03 14:56:18",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Ifeanyi Okechukwu",
      "formatted_date": "Tuesday December 3, 2:56 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3352,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Abisola Adebesin",
      "identifier": "Customer-6693",
      "amount": "54500",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000023241203144254008132320207",
      "transaction_id": "",
      "date_time": "2024-12-03 14:44:07",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Abisola Adebesin",
      "formatted_date": "Tuesday December 3, 2:44 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3351,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Sandra BASSEY",
      "identifier": "Customer-6722",
      "amount": "400000",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000012241203144059093204852153",
      "transaction_id": "",
      "date_time": "2024-12-03 14:41:38",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Sandra BASSEY",
      "formatted_date": "Tuesday December 3, 2:41 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3344,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Alimi Ashiata MONILOLA",
      "identifier": "Customer-6672",
      "amount": "386000",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000013241203132910000009027657",
      "transaction_id": "",
      "date_time": "2024-12-03 13:30:08",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Alimi Ashiata MONILOLA",
      "formatted_date": "Tuesday December 3, 1:30 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3342,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Olalekan Aweda",
      "identifier": "Customer-6679",
      "amount": "213900",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000004241203131803908425827384",
      "transaction_id": "",
      "date_time": "2024-12-03 13:18:23",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Olalekan Aweda",
      "formatted_date": "Tuesday December 3, 1:18 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3338,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for mobolanle Olonde",
      "identifier": "Customer-6911",
      "amount": "55600",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "100004241203111150123115700229",
      "transaction_id": "",
      "date_time": "2024-12-03 12:13:50",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "mobolanle Olonde",
      "formatted_date": "Tuesday December 3, 12:13 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3330,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for AJADI TITILAYO",
      "identifier": "Customer-7179",
      "amount": "55600",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000001241203100700911053917991",
      "transaction_id": "",
      "date_time": "2024-12-03 10:07:58",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "AJADI TITILAYO",
      "formatted_date": "Tuesday December 3, 10:07 AM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3328,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Mobos Elite",
      "identifier": "Customer-7425",
      "amount": "161000",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000023241203092410008132257940",
      "transaction_id": "",
      "date_time": "2024-12-03 09:25:27",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Mobos Elite",
      "formatted_date": "Tuesday December 3, 9:25 AM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3326,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Elliot&#039;s Hair Empire",
      "identifier": "Customer-6846",
      "amount": "3500",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000014241203080743212570112974",
      "transaction_id": "",
      "date_time": "2024-12-03 08:09:43",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Elliot&#039;s Hair Empire",
      "formatted_date": "Tuesday December 3, 8:09 AM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3319,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Eniola Soile",
      "identifier": "Customer-6859",
      "amount": "83100",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "090405241202173137046901872636",
      "transaction_id": "",
      "date_time": "2024-12-02 17:32:37",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Eniola Soile",
      "formatted_date": "Monday December 2, 5:32 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3318,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Oyinlola Adewale",
      "identifier": "Customer-7408",
      "amount": "107600",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000012241202171146519881058774",
      "transaction_id": "",
      "date_time": "2024-12-02 17:16:12",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Oyinlola Adewale",
      "formatted_date": "Monday December 2, 5:16 PM (WAT)",
      "sender_name": ""
  },
  {
      "id": 3314,
      "company_id": 123,
      "employee_id": 0,
      "type": "Customer Wallet Topup",
      "description": "Wallet topup for Olalekan Aweda",
      "identifier": "Customer-6679",
      "amount": "67400",
      "phone_number": "",
      "network": "",
      "bank_name": "",
      "account_number": "",
      "account_name": "",
      "payment_type": "Credit",
      "channel": "Paystack",
      "transaction_reference": "000004241202164301760911333679",
      "transaction_id": "",
      "date_time": "2024-12-02 16:48:04",
      "status": "Completed",
      "extras": "",
      "completed_by": "",
      "fullname": "Olalekan Aweda",
      "formatted_date": "Monday December 2, 4:48 PM (WAT)",
      "sender_name": ""
  }
];

const Wallet = () => {
  // State management

  const [totalPages, setTotalPages] = useState(transactions_data.length);
  const [transactions, setTransactions] = useState([...transactions_data]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // End state management
  const series = [
    {
      name: "Amount Spent",
      data: [20000, 10000, 5000, 45000, 12000, 7500, 24000]
    }
  ];

  const options = {
    chart: {
      height: 350,
      type: "bar"
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#0F973D',
            colorTo: '#E7F6EC',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      // labels: {
      //   show: false,
      //   formatter: function (val) {
      //     return val + "%";
      //   }
      // }
    
    },
    fill: {
      colors: ['#15AB68']
    },
    title: {
      text: 'Total Spent This Week',
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  };

  const paginate = (num) => {
    setCurrentPage(num);
    // navigator(`/transactions?page=${num}`);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    // navigator(`/transactions?page=${currentPage + 1}`);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    // navigator(`/transactions?page=${currentPage - 1}`);
  };

  return (
    <div className="home">
      <Sidebar />

      <div className="main">
        <Navbar />

        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={AdminIcon} alt="group" />
                  <h5 className="mb-1">Wallet</h5>
                </div>
              </div>
              <Row>
                <div className='virtual-card-contain' >
                  <div className="">
                  <div className="virtual-card">
                    <img src={Pattern} alt="pattern" className='virtual-card-pattern' />
                    <div className='bg-dark-overlay' ></div>
                    <div className='virtual-info' >
                      <div className='pt-2' >
                        <img src={EMVChip} alt="chip" style={{width: 50, height: 42}} />
                      </div>
                      <div className=' pb-2 pt-4' >
                        <div>
                          <p className='available-balance' >
                            {/* Available balance:  */}
                            <span className='available-balance-fee' >200,00.14</span>
                          </p>
                          <p className='text-center mb-3' >PARCELFLOW/ USER ACCOUNT</p>
                          <div className="d-flex justify-content-between align-items-end">
                            <div>
                              <p>0129384929</p>
                              <p>WEMA BANK</p>
                            </div>
                            <div className='account-copy' >
                              <FaCopy /> Copy
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='pb-3 pt-3 d-flex gap-3 justify-content-center' >
                    <button className='btn-secondary' >Top up via Paystack</button>
                    <button className='btn-primary' >Top up via Flutterwave</button>
                  </div>
                  </div>
                </div>
              </Row>
              {/* <Row>
                <Col xs={12} lg={8} >
                  <div className=" w-100 p-2 gap-3 g-2 wallet-top-cards">
                    <div className="col-md-6 w-100 card-item col-xl-4">
                      <div className="w-100">
                        <div className="mb-4">
                          <p className="card-title">Total Spent</p>
                          <p className="card-sub">Sum total of revenue spent</p>
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                          <p className="card-data">
                            &#8358; 200, 00
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 w-100 card-item col-xl-4">
                      <div className="w-100">
                        <div className="mb-4">
                          <p className="card-title">Total Funded</p>
                          <p className="card-sub">Sum total of revenue spent</p>
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                          <p className="card-data">
                            &#8358; 200, 00
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 w-100 card-item col-xl-4">
                      <div className="w-100">
                        <div className="mb-4">
                          <p className="card-title">Balance Available</p>
                          <p className="card-sub">Sum total of revenue spent</p>
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                          <p className="card-data">
                            &#8358; 200, 00
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} lg={4} >
                  
                </Col>
              </Row> */}
              <div className='mt-3' >
                <ReactApexChart options={options} series={series} type="bar" height={350} />
              </div>
            </div>

            <div
              className="card border-0 p-4 mt-4"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={TransactionIcon} alt="group" />
                  <h5 className="mb-1">Transactions</h5>
                </div>
              </div>

              {/* Transaction Table */}
              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Sender</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Type</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {transactions.slice((currentPage-1)*10, (currentPage)*10)?.map((transaction) => {
                        const dateTime = moment(transaction.date_time);
                        const timestamp = dateTime.format("ddd MMM Do, h:mm A");

                        const color =
                          transaction.payment_type === "Credit"
                            ? "green"
                            : "red";
                        return (
                          <tr
                            key={transaction.id}
                          >
                            <td>{transaction.fullname}</td>
                            <td>{transaction.type}</td>
                            <td>
                              ₦
                              {parseFloat(transaction.amount).toLocaleString(
                                "en"
                              )}
                            </td>
                            <td>
                              <span className="d-flex align-items-center gap-1">
                                <GoPrimitiveDot style={{ color: color }} />
                                {transaction.payment_type}
                              </span>
                            </td>
                            <td>{timestamp}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                {
                  transactions.length < 1 && !isLoading && (
                    <EmptyScreen
                      message={"No transaction placed!"}
                    />
                  )
                }
              </div>

              {!isLoading && transactions_data.length > 0 && (
                <Pagination
                  total_pages={2}
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  current_page={currentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wallet