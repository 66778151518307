import * as React from "react";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { getAllCarriersUrl } from "../../../library/URLs";
import { UserProfileData } from "../../../library/constants";
import styled from "styled-components";
import AramexAccountDialog from "../../../modals/AramexAccountDialog";
import DHLAccountDialog from "../../../modals/DHLAccountDialog";
import ManageIcon from "../../../assets/settings/account_settings/manage_accounts.svg";
import { Col, Row } from "react-bootstrap";
import { styled as mui_styled } from "@mui/material/styles";
import { Switch, Tooltip } from "@mui/material";
import { TbCash, TbPencil } from "react-icons/tb";
import AddCarrierTypeDialog from "../../../modals/AddCarrierTypeDialog";
import { BsPlus, BsPlusCircleFill } from "react-icons/bs";
import UpdateCarrierTypeDialog from "../../../modals/UpdateCarrierTypeDialog";
import UpdatePriceMarginDialog from "../../../modals/UpdatePriceMarginDialog";
import ActivateCarrierDialog from "../../../modals/ActivateCarrierDialog";
import DeactivateCarrierDialog from "../../../modals/DeactivateCarrierDialog";
import CourierSettings from "./CourierSettings";
import UpdateLocalMarginDialog from "../../../modals/UpdateLocalMarginDialog";
import AddLocalCarrierType from "../../../modals/AddLocalCarrierType";
import UpdateLocalCarrierTypeDialog from "../../../modals/UpdateLocalCarrierTypeDialog";

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AccountSettings = () => {
  const form = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [intracityCarriers, setIntracityCarriers] = useState([]);
  const [interstateCarriers, setInterstateCarriers] = useState([]);
  const [internationalCarriers, setInternationalCarriers] = useState([]);
  const [importCarriers, setImportCarriers] = useState([]);

  const [showAddCarrier, setShowAddCarrier] = useState(null);
  const [showUpdateCarrier, setShowUpdateCarrier] = useState(null);
  const [showMargin, setShowMargin] = useState(null);

  const [showAddLocalCarrier, setShowAddLocalCarrier] = useState(null);
  const [showUpdateLocalCarrier, setShowUpdateLocalCarrier] = useState(null);
  const [showLocalMargin, setShowLocalMargin] = useState(null);

  const [showActivateCarrier, setShowActivateCarrier] = useState(null);
  const [showDeactivateCarrier, setShowDeactivateCarrier] = useState(null);

  const [showAramexAccountVisibility, setShowAramexAccountDialogVisibility] =
    useState(false);
  const [showDHLAccountVisibility, setShowDHLAccountDialogVisibility] =
    useState(false);

  const fetchAllCarriers = async () => {
    await axios
      .post(getAllCarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let intracity = res?.data?.data?.filter(
            (carrier) => carrier?.shipment_type === "Local"
          );
          let interstate = res?.data?.data?.filter(
            (carrier) => carrier?.shipment_type === "Interstate"
          );
          let international = res?.data?.data?.filter(
            (carrier) => carrier?.shipment_type === "International"
          );
          let import_carriers = res?.data?.data?.filter(
            (carrier) => carrier?.shipment_type === "Import"
          );

          setIntracityCarriers(intracity);
          setInterstateCarriers(interstate);
          setInternationalCarriers(international);
          setImportCarriers(import_carriers);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchAllCarriers();
  }, []);

  const toggleAramexDialog = (type) => {
    type === "open"
      ? setShowAramexAccountDialogVisibility(true)
      : setShowAramexAccountDialogVisibility(false);
  };

  const toggleDHLDialog = (type) => {
    type === "open"
      ? setShowDHLAccountDialogVisibility(true)
      : setShowDHLAccountDialogVisibility(false);
  };

  return (
    <>
      {isError === false ? null : (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      )}

      {isSuccess === false ? null : (
        <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
      )}

      <AddCarrierTypeDialog
        isModalVisible={showAddCarrier !== null}
        handleCloseDialog={() => setShowAddCarrier(null)}
        shipment_type={showAddCarrier}
      />

      <UpdateCarrierTypeDialog 
        isModalVisible={showUpdateCarrier !== null}
        handleCloseDialog={() => setShowUpdateCarrier(null)}
        carrier_details={showUpdateCarrier}
      />

      <UpdatePriceMarginDialog 
        isModalVisible={showMargin !== null}
        handleCloseDialog={() => setShowMargin(null)}
        carrier={showMargin}
      />

      <ActivateCarrierDialog 
        isModalVisible={showActivateCarrier !== null}
        handleCloseDialog={() => setShowActivateCarrier(null)}
        carrierID={showActivateCarrier}
        fetchCarriers={fetchAllCarriers}
      />

      <DeactivateCarrierDialog 
        isModalVisible={showDeactivateCarrier !== null}
        handleCloseDialog={() => setShowDeactivateCarrier(null)}
        carrierID={showDeactivateCarrier}
        fetchCarriers={fetchAllCarriers}
      />

      <UpdateLocalMarginDialog 
        isModalVisible={showLocalMargin !== null}
        handleCloseDialog={() => setShowLocalMargin(null)}
        carrier={showLocalMargin}
      />

      <AddLocalCarrierType 
        isModalVisible={showAddLocalCarrier !== null}
        handleCloseDialog={() => setShowAddLocalCarrier(null)}
        shipment_type={showAddLocalCarrier}
      />

      <UpdateLocalCarrierTypeDialog 
        isModalVisible={showUpdateLocalCarrier !== null}
        handleCloseDialog={() => setShowUpdateLocalCarrier(null)}
        carrier_details={showUpdateLocalCarrier}
      />

      <div className="settings_section">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-3 align-items-start">
            <img src={ManageIcon} alt="manage" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Account Settings
              </p>
              <span>Edit your business operations to your preferences</span>
            </div>
          </div>
        </div>
        <div className="row mt-4 border-top px-4">
          <form ref={form}>
            <Row className=" form-row py-4">
              <Col className="form-group col-xl-6 col-md-12 account-details-border">
                <div className=" py-2">
                  <div>
                    <span style={{ color: "#2C3E50" }}>
                      Intracity Processor
                    </span>
                  </div>
                  <div>
                    {intracityCarriers?.map((carrier) => (
                      <div className="mt-4 w-100 d-flex justify-content-between align-items-center">
                        <label
                          className="form-check-label"
                          htmlFor="localShipment"
                        >
                          {carrier.masked_name}
                        </label>
                        <div className="d-flex gap-4 align-items-center">
                          <Tooltip title="Price Margin" placement="top">
                            <span className="d-flex align-items-start">
                              <TbCash
                                style={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setShowLocalMargin({ carrier_id: carrier.id, carrier_name: carrier.masked_name, shipment_type: "Local" })}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title="Edit Processor" placement="top">
                            <span className="d-flex align-items-start">
                              <TbPencil
                                style={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setShowUpdateLocalCarrier(carrier)}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title="Toggle status" placement="top">
                            <ParcelflowSwitch
                              defaultValue="0"
                              name="create_employee_shipment"
                              onChange={
                                carrier.status === "Active" ?
                                () => setShowDeactivateCarrier(carrier.id) :
                                () => setShowActivateCarrier(carrier.id)
                              }
                              checked={carrier.status === "Active"}
                              className="react-switch"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                    <button
                      className="mt-2 mb-4 add-carrier"
                      type="button"
                      onClick={() => setShowAddLocalCarrier("Local")}
                    >
                      <BsPlusCircleFill /> Add Carrier
                    </button>
                  </div>
                </div>
              </Col>
              <Col className="form-group ps-md-5 col-xl-6 col-md-12">
                <div className=" py-2">
                  <div>
                    <div>
                      <span style={{ color: "#2C3E50" }}>
                        Interstate Processor
                      </span>
                    </div>
                    <div>
                      {interstateCarriers?.map((carrier) => (
                        <div className="mt-4 w-100 d-flex justify-content-between align-items-center">
                          <label
                            className="form-check-label"
                            htmlFor="localShipment"
                          >
                            {carrier.masked_name}
                          </label>
                          <div className="d-flex gap-4 align-items-center">
                            <Tooltip title="Price Margin" placement="top">
                              <span className="d-flex align-items-start">
                                <TbCash
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => setShowMargin({ carrier_id: carrier.id, carrier_name: carrier.masked_name, shipment_type: "Interstate" })}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title="Edit Processor" placement="top">
                              <span className="d-flex align-items-start">
                                <TbPencil
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => setShowUpdateCarrier(carrier)}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title="Toggle status" placement="top">
                              <ParcelflowSwitch
                                defaultValue="0"
                                name="create_employee_shipment"
                                onChange={
                                  carrier.status === "Active" ?
                                  () => setShowDeactivateCarrier(carrier.id) :
                                  () => setShowActivateCarrier(carrier.id)
                                }
                                checked={carrier.status === "Active"}
                                className="react-switch"
                              />
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                      <button
                        className="mt-2 mb-4 add-carrier"
                        type="button"
                        onClick={() => setShowAddCarrier("Interstate")}
                      >
                        <BsPlusCircleFill /> Add Carrier
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className=" form-row border-top py-4">
              <Col className="form-group col-xl-6 col-md-12 account-details-border">
                <div className=" py-2">
                  <div>
                    <div>
                      <span style={{ color: "#2C3E50" }}>Export Processor</span>
                    </div>
                    <div>
                      {internationalCarriers?.map((carrier) => (
                        <div className="mt-4 d-flex w-100 justify-content-between align-items-center">
                          <label
                            className="form-check-label"
                            htmlFor="localShipment"
                          >
                            {carrier.masked_name}
                          </label>
                          <div className="d-flex gap-4 align-items-center">
                            <Tooltip title="Price Margin" placement="top">
                              <span className="d-flex align-items-start">
                                <TbCash
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => setShowMargin({ carrier_id: carrier.id, carrier_name: carrier.masked_name, shipment_type: "International" })}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title="Edit Processor" placement="top">
                              <span className="d-flex align-items-start">
                                <TbPencil
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => setShowUpdateCarrier(carrier)}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title="Toggle status" placement="top">
                              <ParcelflowSwitch
                                defaultValue="0"
                                name="create_employee_shipment"
                                onChange={
                                  carrier.status === "Active" ?
                                  () => setShowDeactivateCarrier(carrier.id) :
                                  () => setShowActivateCarrier(carrier.id)
                                }
                                checked={carrier.status === "Active"}
                                className="react-switch"
                              />
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                      <button
                        className="mt-2 mb-4 add-carrier"
                        type="button"
                        onClick={() => setShowAddCarrier("International")}
                      >
                        <BsPlusCircleFill /> Add Carrier
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="form-group ps-md-5 col-xl-6 col-md-12">
                <div className="d-flex flex-row justify-content-between align-items-center py-2">
                  <div className="" style={{ width: "100%" }}>
                    <div>
                      <span style={{ color: "#2C3E50" }}>Import Processor</span>
                    </div>

                    <div>
                      {importCarriers?.map((carrier) => (
                        <div className="mt-4 d-flex justify-content-between w-100 align-items-center">
                          <label
                            className="form-check-label"
                            htmlFor="localShipment"
                          >
                            {carrier.masked_name}
                          </label>
                          <div className="d-flex gap-4 align-items-center">
                            <Tooltip title="Price Margin" placement="top">
                              <span className="d-flex align-items-start">
                                <TbCash
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => setShowMargin({ carrier_id: carrier.id, carrier_name: carrier.masked_name, shipment_type: "Import" })}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title="Edit Processor" placement="top">
                              <span className="d-flex align-items-start">
                                <TbPencil
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => setShowUpdateCarrier(carrier)}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title="Toggle status" placement="top">
                              <ParcelflowSwitch
                                defaultValue="0"
                                name="create_employee_shipment"
                                onChange={
                                  carrier.status === "Active" ?
                                  () => setShowDeactivateCarrier(carrier.id) :
                                  () => setShowActivateCarrier(carrier.id)
                                }
                                checked={carrier.status === "Active"}
                                className="react-switch"
                              />
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                      <button
                        className="mt-2 mb-4 add-carrier"
                        type="button"
                        onClick={() => setShowAddCarrier("Import")}
                      >
                        <BsPlusCircleFill /> Add Carrier
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>

      <div>
        <CourierSettings />
      </div>
    </>
  );
};

export default AccountSettings;
